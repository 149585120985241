import styles from './DevExperience.module.scss';
import { FrameworkSection } from './FrameworkSection/FrameworkSection';
import { Title } from '../common/Title/Title';
import { Paragraph } from '../common/Paragraph/Paragraph';
import { Link } from '../common/Link/Link';

export const DevExperience = () => {
  return (
    <section id="frontend" className={styles.devExperience}>
      <Title className={styles.title}>Experience in frontend</Title>

      <div className={styles.summary}>
        <Paragraph className={styles.paragraph}>
          Frontend is my favorite pastime.
        </Paragraph>

        <Paragraph className={styles.paragraph}>
          During a few years of my professional growth I've worked on about a dozen various projects at different
          stages: <span className={styles.noWrap}>long-term</span> development, startup, discovery, redesign from scratch,
          migration to another framework, pet projects, <span className={styles.noWrap}>open-source</span>, etc.
          I've worked independently, as part of a team, in the head of frontend development.
        </Paragraph>

        <Paragraph className={styles.paragraph}>
          My overall experience in frontend is more than <strong>5 years</strong>,
          all of them I worked at <Link href="https://itransition.com/">Itransition</Link>.
          In&nbsp;March&nbsp;2024 I quit it and moved to Tokyo.
          Now I am actively looking for a job in Japan.
          Here is my <Link href="/assets/Tanian%20Vincent%20-%20Frontend%20Engineer.pdf" download>resume</Link>.
        </Paragraph>
      </div>

      <div className={styles.frameworks}>
        <FrameworkSection
          className={styles.angular}
          name="Angular"
          experience="~3 years"
          projects="4-5"
          tools={['Typescript', 'RxJS', 'Jasmine', 'Cypress', 'Less', 'Scss', 'i18n']}
        >
          Starting project from scratch. Setting up all necessary dev processes.
          Developing respecting SOLID, design patterns, unit/integration testing.
          Adaptive and responsive design, localization.
          Service-based state management.
          Technical leadership, code review.
        </FrameworkSection>

        <FrameworkSection
          className={styles.react}
          name="React"
          experience="~2 years"
          projects="6-7"
          tools={['Typescript', 'Redux', 'Highcharts', 'ApexCharts', 'Less', 'Scss', 'antd', 'i18n']}
        >
          Starting project from scratch. Setting up all necessary dev processes.
          Developing respecting SOLID, functional style principles.
          Adaptive and responsive design, charts, localization.
          Store-based, context-based state management.
          Reducing bundle size.
          Code review.
        </FrameworkSection>
      </div>
    </section>
  );
};
